<template>
    <div class="checkout container">
      <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
        <div class="header_title">Confirm request</div>
        <div></div>
      </div>
      <div class="page_width scoller grid_content" style="padding-bottom:60px">
        <div class="checkout_product">
          <div class="product_group flex">
            <div class="product_img">
              <img :src="jsonObject.image" alt="">
            </div>
            <div class="product_msg">
              <div class="product_title"> {{ jsonObject.name }} </div>
              <!-- <div class="product_type">980+110 Oneiric Shard</div> -->
            </div>
          </div>
          <div class="product_count flex_end">X {{ jsonObject.quantity }}</div>
          <div class="product_price flex_end" > {{currency.remark}}{{ jsonObject.unitPrice }}</div>
          <div class="product_total flex_sb">
            <div class="total_title">Total</div>
            <div class="total_price"> {{currency.remark}} {{ jsonObject.amount }}</div>
          </div>
        </div>
        <div class="pay_before" v-if="isAgree==-1">
          <div class="before_title flex">
            <div class="title_icon flex_center"><svg t="1729498015899" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3976" width="16" height="16"><path d="M427.8 387.3H264.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h163.2c8.8 0 16 7.2 16 16s-7.2 16-16 16zM501.8 583.6H264.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h237.2c8.8 0 16 7.2 16 16s-7.2 16-16 16zM614.8 779.9H264.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h350.2c8.8 0 16 7.2 16 16s-7.2 16-16 16zM688.2 486.2c-8.8 0-17.3-2.7-24.5-7.9l-80.6-58c-18.8-13.5-23.1-39.8-9.5-58.5l164-227.9c7.9-10.9 20.6-17.4 34.1-17.4 8.8 0 17.3 2.7 24.5 7.9l80.6 58c18.8 13.5 23.1 39.8 9.5 58.5l-164 227.9c-7.9 10.9-20.6 17.4-34.1 17.4z m83.5-337.7c-3.2 0-6.2 1.5-8.1 4.1l-164 227.9c-3.2 4.4-2.2 10.7 2.3 13.9l80.6 58c2.2 1.5 4.3 1.9 5.8 1.9 3.2 0 6.2-1.5 8.1-4.1l164-227.9c3.2-4.4 2.2-10.7-2.3-13.9l-80.6-58c-2.2-1.6-4.4-1.9-5.8-1.9z" fill="#c1c1c1" p-id="3977"></path><path d="M565 548.2c-3.3 0-6.6-1-9.3-3-4.6-3.3-7.2-8.9-6.6-14.6L562.9 390c0.6-5.7 4.1-10.6 9.3-13 5.2-2.4 11.3-1.8 15.9 1.6L703 460.9c4.6 3.3 7.2 8.9 6.6 14.6-0.6 5.7-4.1 10.6-9.3 13l-128.7 58.4c-2.1 0.9-4.4 1.3-6.6 1.3z m27-127.5l-8.4 85.5 78.2-35.5-69.8-50zM830.1 294.5c-3.2 0-6.5-1-9.3-3l-107.5-77.3c-7.2-5.2-8.8-15.2-3.6-22.3 5.2-7.2 15.2-8.8 22.3-3.6l107.5 77.3c7.2 5.2 8.8 15.2 3.6 22.3-3.2 4.3-8.1 6.6-13 6.6z" fill="#c1c1c1" p-id="3978"></path><path d="M756.1 550.3V864c0 6.5-5.3 11.7-11.7 11.7h-544c-6.5 0-11.7-5.3-11.7-11.7V192c0-6.5 5.3-11.7 11.7-11.7h426c8.8 0 16-7.2 16-16s-7.2-16-16-16h-426c-24.2 0-43.7 19.6-43.7 43.7v672c0 24.2 19.6 43.7 43.7 43.7h544c24.2 0 43.7-19.6 43.7-43.7V550.3c0-8.8-7.2-16-16-16s-16 7.1-16 16z" fill="#c1c1c1" p-id="3979"></path></svg></div>
            <div class="title_text ">Cancellation of order</div>
          </div>
          <el-form label-position="top" label-width="80px" :model="payForm" ref="ruleForm"  >
            <el-form-item label="Type" >
              <el-input v-model="refundTypeValue" @focus="showPicker('refundType')">
                <i slot="suffix" class="suffix_icon flex_center" :class="[pickerVisible?'suffix_icon_rotate':'']"><svg t="1729567486803" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2277" width="16" height="16"><path d="M830.24 340.688l11.328 11.312a16 16 0 0 1 0 22.624L530.448 685.76a16 16 0 0 1-22.64 0L196.688 374.624a16 16 0 0 1 0-22.624l11.312-11.312a16 16 0 0 1 22.624 0l288.496 288.496 288.512-288.496a16 16 0 0 1 22.624 0z" fill="#161619" p-id="2278"></path></svg></i>
              </el-input>
            </el-form-item> 
            <el-form-item label="Reason">
              <el-input v-model="refundReasonIdValue" @focus="showPicker('refundReasonId')">
                <i slot="suffix" class="suffix_icon flex_center" :class="[pickerVisible?'suffix_icon_rotate':'']"><svg t="1729567486803" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2277" width="16" height="16"><path d="M830.24 340.688l11.328 11.312a16 16 0 0 1 0 22.624L530.448 685.76a16 16 0 0 1-22.64 0L196.688 374.624a16 16 0 0 1 0-22.624l11.312-11.312a16 16 0 0 1 22.624 0l288.496 288.496 288.512-288.496a16 16 0 0 1 22.624 0z" fill="#161619" p-id="2278"></path></svg></i>
              </el-input>
            </el-form-item>
          
          </el-form>
        </div>
        <div class="err_cont" v-if="jsonObject.remark.deliveryInfo.errors&&isAgree==0">
          <div class="err_text" v-for="(i,index) in jsonObject.remark.deliveryInfo.errors" :key="index">{{ i.message }}</div>
        </div>
        <control-type :result="result" v-if="hasResult&&isAgree==0" @input="getObj"></control-type>
    
      </div>
      <div class="bottom_group flex_center">
        <div class="btn_msg">
          <div class="msg_text">The amount actually paid:</div>
          <div class="msg_price">{{currency.remark}}{{ jsonObject.amount }}</div>
        </div>
        <div class="group_btn">
          <div class="btn def_btn" @click="pay()" v-if="isAgree==0">Pay Now</div>
          <div class="btn def_btn" @click="cancelOrder()" v-if="isAgree==-1">Cancel</div>
        </div>
      </div>
      <VuePicker :data="refundTypeOptions" :showToolbar="true"  @confirm="confirm" :visible.sync="pickerVisible" cancelText="Cancel" confirmText="Confirm" />
      <VuePicker :data="refundReasonIdOptions" :showToolbar="true"  @confirm="confirm2" :visible.sync="pickerVisible2" cancelText="Cancel" confirmText="Confirm" />
    </div>
  </template>
  
  <script>
  import VuePicker from 'vue-pickers';
  import controlType from '../../components/controlType.vue';
  import { divide } from '../../utils/tool';

  export default {
    components:{
      VuePicker,
      controlType
    },
    data(){
      return {
        radio_select:false,
        pickerVisible:false,
        pickerVisible2:false,
        payForm:{
          region:'',
          id:'',
          email:'',
          cpf:'',
          name:'',
        },
        rules:{
          region: [ { required: false, message: '请选择活动区域', trigger: 'blur' }],
          id: [  { required: true, message: 'Enter ID', trigger: 'blur' } ],
          email: [  { required: true, message: 'Please enter your e-mail', trigger: 'blur' } ],
          name: [  { required: true, message: 'Enter the full name.', trigger: 'blur' } ],
          cpf: [  { required: true, message: 'Please enter the CPF', trigger: 'blur' } ],
        },
        onlyOptions: [
          [
          { value: '30000', label: 'Does not match the product description' },
          { value: '30010', label: 'Failure to deliver on time' },
          { value: '30020', label: 'Not received/partially delivered' },
          { value: '10000', label: 'Game maintenance/game purchase restriction' },
          { value: '30040', label: 'Account and equipment retrieved/recharge not received/card used' },
          { value: '0', label: 'other' },
         
          ]
        ],
        deliveryOptions: [
          [
          { value: '30000', label: 'Does not match the product description' },
          { value: '30010', label: 'Failure to deliver on time' },
          { value: '30030', label: 'Product out of stock' },
          { value: '10000', label: 'Game maintenance/game purchase restriction' },
          { value: '20010', label: 'Account restriction login/account restriction purchase/account password error multiple times/regional server error/account without role/unable to obtain verification code' },
          { value: '20000', label: "I don't want it anymore/I bought the wrong product/I bought the wrong quantity" },
          { value: '0', label: 'other' },
         
          ]
        ],
        refundTypeOptions:[
          [
          { value: 'REFUND', label: 'Refund only' },
          { value: 'REFUND_AND_CANCEL_DELIVERY', label: 'Refund and cancel delivery' },
          ]
        ],
        refundReasonIdOptions:[ ],
        result:{},
        hasResult:false,
        jsonObject:{},
        product:{},
        goodsId:'',
        id:'',
        goodsNum:1,
        isAgree:0,
        refundTypeValue:'',
        refundReasonIdValue:'',
        refundType:'',
        refundReasonId:'',
      }
  
    },
    created(){
      this.id = this.$route.query.orderId
      this.isAgree = this.$route.query.isAgree
      this.getDate()
    },
    methods:{
      toBack(){
        this.$router.back()
      },
      
      confirm(e){  
        this.refundTypeValue = e[0].label
        this.refundType = e[0].value
        if( this.refundType == 'REFUND'){
          this.refundReasonIdOptions = this.onlyOptions
        }
        if( this.refundType == 'REFUND_AND_CANCEL_DELIVERY'){
          this.refundReasonIdOptions = this.deliveryOptions
        }
        console.log('confirm',this.refundTypeValue, this.refundType)
      },
      confirm2(e){  
        this.refundReasonIdValue = e[0].label
        this.refundReasonId = e[0].value
        console.log('confirm',this.refundReasonIdValue, this.refundReasonId)
      },
      showPicker(type){
        if(type=='refundReasonId'){
          this.pickerVisible2 = true
        }
        if(type=='refundType'){
          this.pickerVisible = true
        }
       
        console.log('pickerVisible',this.pickerVisible)
      },
      getObj(obj){
        let result = this.jsonObject.remark
        console.log('result',result)
        result.deliveryInfo.sections = obj
        this.jsonObject.remark = result
        console.log('obj',obj)
      },
    
      getDate(){
        let id = this.id
        this.$axiosApi.getOrderDetail({id}).then(res=>{
            if(res.code == 200){
                let jsonObject = res.data
                jsonObject.unitPrice = divide(res.data.unitPrice)
                jsonObject.amount = divide(res.data.amount)
       
                let result = JSON.parse(res.data.remark)
                console.log('result',result)
                jsonObject.remark = result
                this.jsonObject = jsonObject
                this.result = result.deliveryInfo.sections
                this.hasResult = true
                
                
                /* 
                控件类型：文本-TextControl，
                密码-PasswordControl，
                数字-NumericControl，
                单选-SingleSelectControl，
                单选下拉-DropDownSingleSelectControl，
                多选-MultiSelectControl，
                多选下拉-DropDownMultiSelectControl，
                图片-ImageControl
                */ 
                
                
            }
        })
      },
     
      pay(){
        let id = this.id
        let isAgree = this.isAgree
        let jsonObject = this.jsonObject.remark
        let refundReasonId = this.refundReasonId
        let refundType = this.refundType
        let _this = this
        
        this.$axiosApi.submitDelivery({isAgree,jsonObject,id,refundType,refundReasonId}).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            setTimeout(()=>{
              _this.$router.push('/order')
            },1200)
          }
        })
      },
      cancelOrder(){
        let id = this.id
        let isAgree = this.isAgree
        let jsonObject = {}
        let refundReasonId = this.refundReasonId
        let refundType = this.refundType
        if(!refundType){
          this.$message.error('Please fill in the cancellation type')
          return
        }
        if(!refundReasonId){
          this.$message.error('Please fill in the reason for cancellation')
          return
        }
        let _this = this
        this.$axiosApi.submitDelivery({isAgree,jsonObject,id,refundType,refundReasonId}).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            setTimeout(()=>{
              _this.$router.push('/order')
            },1200)
          }
        })
      }
    },
    computed:{
      currency(){return this.$store.state.currency}
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .checkout{
    background: #f6f6f8 !important;
    .err_cont{
      text-align: left;
      color:#f77e1a;
      font-size: 12px;
      padding:6px;
    }
    .checkout_product{
      padding:13px;
      border-radius: 10px;
      background:#fff;
      margin-top:16px;
      margin-bottom:8px;
      .product_group{
        .product_img{
          width: 115px;
          height: 115px;
          overflow: hidden;
  
        }
        .product_msg{
          margin-left:13px;
          text-align: left;
          font-size: 16px;
          .product_title{
            color:#222425;
            font-weight: bold;
            margin-bottom:10px;
          }
          .product_type{
            color:#909194;
            font-size: 14px;
          }
        }
      }
      .product_count{
        color:#909194;
      }
      .product_price{
        color:#222425;
      }
      .product_total{
        padding-top:16px;
        margin-top:10px;
        border-top: 1px solid #ededf2;
        font-size:14px;
        color:#161619;
        .total_price{
          color:#f77e1a;
        }
      }
    }
    .pay_before{
      border-radius: 10px;
      background:#fff;
      margin-top:16px;
      padding:13px;
      text-align: left;
      .before_title{
        padding:13px 0;
        .title_text{
          color:#222425;
          margin-left:4px;
        }
      }
      /deep/.el-form-item {
        margin-bottom:10px;
      }
      /deep/.el-form-item__label{
        font-size: 13px;
        color:#161619;
        height: 34px;
        padding:0;
      }
      .suffix_icon{
        height: 100%;
        // transform: rotate(-90deg);
        translate: transform 0.35s ease-in-out;
      }
      .suffix_icon_rotate{
        transform: rotate(180deg)
      }
    }
    .pay_list{
      margin-top:16px;
      margin-bottom:64px;
      .pay_list_title{
        font-size: 14px;
        color:#161619;
        font-weight: bold;
        margin-bottom:12px;
        text-align: left;
      }
      .pay_list_mod{
        border-radius: 6px;
        background:#fff;
        border:1px solid #f77e1a;
        .pay_mod_top{
          padding:10px;
          .mod_agree{
            width:20px;
            height: 20px;
            margin-right:14px;
            background:url(https://global-cdn.bigplayers.com/m/img/yellow/select.png);
            background-size: 100% 100%;
          }
          .mod_img{
            height: 32px;
            >img{
              height: 100%;
              width: auto;
            }
          }
        }
        .form_content{
          padding:0 12px 15px;
          .radio_content{
            color:#222425;
            .radio_label{
              width:20px;
              height: 20px;
              margin-right:14px;
            }
          }
        }
      }
    }
    .bottom_group{
      height: 54px;
      padding:0 17px;
      position: absolute;
      bottom:0;
      left:0;
      right:0;
      background:#fff;
      z-index: 9;
      .btn_msg{
        flex:1;
        text-align: right;
        padding-right:14px;
        .msg_text{
          color:#909194;
          font-size: 14px;
        }
        .msg_price{
          color:#f77e1a;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .group_btn{
        width:120px;
      }
    }
    /deep/.el-input__inner{
      font-size: 14px;
      &:focus{
        border:1px solid #DCDFE6;
      }
    }
    .pickerbox{
      position: absolute;
      height: 100%;
      width: 100%;
      /deep/.left{
        .btn{
          color:#909194;
        }
        
      }
      /deep/.right{
        .btn{
          color:#f77e1a;
        }
        
      }
    }
    
    // .vue-picker {
    //   --picker-background-color: #f0f0f0;
    //   --picker-text-color: #333;
    // }
    
  }
  
  </style>