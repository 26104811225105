<template>
    <div>
        <div class="pay_list" v-for="(item,index) in result" :key="index">
        <div class="pay_list_title">{{ item.name}}</div>
        <div class="pay_list_mod">
          <div class="pay_mod_top flex_start_center">
            <!-- <div class="mod_agree"></div> -->
            <!-- <div class="mod_img">
              <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000002FMfhF2vE2Nb0000UBEWLOG000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=ILh2aFpJyyKk7pj0ZxXKJZFq6s8%3D" alt="">
            </div> -->
          </div>
          <div class="form_content" v-for="(cur,idx) in item.tables" :key="idx">
            <el-form label-position="left" label-width="80px" :model="payForm" ref="ruleForm" >
              <el-form-item :label="head.name"  v-for="(head,headIdx) in cur.headers" :key="headIdx">
                <div v-if="head.control.type=='TextControl'" class="flex_col more_col">
                  <el-input v-for="(control,conIdx) in cur.controls" :key="conIdx" v-model="control[headIdx].value" type="text"  @input="handleInput" :disabled="!head.control.configurationForResponder.editable"></el-input>
                </div>
                <div v-if="head.control.type=='PasswordControl'" class="flex_col more_col">
                  <el-input v-for="(control,conIdx) in cur.controls" :key="conIdx" v-model="control[headIdx].value" type="password"  @input="handleInput" :disabled="!head.control.configurationForResponder.editable"></el-input>        
                </div>
                 <div v-if="head.control.type=='NumericControl'" class="flex_col more_col">
                  <el-input-number v-for="(control,conIdx) in cur.controls" :key="conIdx" size="mini" :min="control[0].minValue" :max="control[0].maxValue"  v-model="control[headIdx].value"   @change="handleInput" :disabled="!head.control.configurationForResponder.editable"></el-input-number>
                </div>
                <div v-if="head.control.type=='SingleSelectControl'" class="flex_warp">
                  <div v-for="(control,conIdx) in cur.controls" :key="conIdx" class="flex_col more_col">
                    <div>
                      <el-radio v-model="control[headIdx].value" :label="rad" v-for="(rad,radIdx) in control[headIdx].options" :key="radIdx" @input="handleInput" :disabled="!head.control.configurationForResponder.editable">{{ rad }}</el-radio>
                 
                    </div>
                   </div>
                </div>
                <div v-if="head.control.type=='DropDownSingleSelectControl'" >
                  <div v-for="(control,conIdx) in cur.controls" :key="conIdx">
                    <el-select v-model="control[headIdx].value" placeholder="" @change="handleInput($event,'select'+headIdx)" :disabled="!head.control.configurationForResponder.editable" :id="'select'+headIdx">
                        <el-option
                        v-for="sigItem in control[headIdx].options"
                        :key="sigItem"
                        :label="sigItem"
                        :value="sigItem">
                        </el-option>
                    </el-select>
                  </div>
                    
                </div>
                <div v-if="head.control.type=='MultiSelectControl'">
                  <div v-for="(control,conIdx) in cur.controls" :key="conIdx">
                    <el-checkbox-group v-model="control[headIdx].values" @change="handleInput" :disabled="!head.control.configurationForResponder.editable">
                      <el-checkbox :label="che" v-for="(che,cheIdx) in control[headIdx].options" :key="cheIdx"></el-checkbox> 
                    </el-checkbox-group>
                  </div>
                   
                </div>
                <div v-if="head.control.type=='DropDownMultiSelectControl'">
                  <div v-for="(control,conIdx) in cur.controls" :key="conIdx">
                    <el-select v-model="control[headIdx].values" multiple placeholder="" @change="handleInput" :disabled="!head.control.configurationForResponder.editable">
                        <el-option
                        v-for="milItem in control[headIdx].options"
                        :key="milItem"
                        :label="milItem"
                        :value="milItem">
                        </el-option>
                    </el-select>
                  </div>
                    
                </div>
                <!-- head.control.maxImage -->
                <div class="up_content " v-if="head.control.type=='ImageControl'">
                  
                  
                  <div v-for="(control,conIdx) in cur.controls" :key="conIdx" class="flex_warp" style="padding-bottom:8px">
                    <div v-if="!head.control.configurationForResponder.editable">
                      <div v-if="showPic && !head.control.configurationForResponder.editable" class="show_pic flex_warp">
                        <img v-for="(img,imgIdx) in control[headIdx].fileList" :key="imgIdx" :src="img.url" alt="" >
                      </div>
                    </div>
                    <div v-if="showPic&&head.control.configurationForResponder.editable">
                      <el-upload
                      :action="upUrl+'test_api/api/common/uploadChain'"
                      list-type="picture-card"
                      :disabled="!head.control.configurationForResponder.editable"
                      :headers="config"
                      :data="upData"
                      :file-list="control[headIdx].fileList"
                      :on-success="(res,file,fileList)=>handleAvatarSuccess(control[headIdx],res,file,fileList)"
                      :before-upload="beforeAvatarUpload"
                      :on-preview="handlePictureCardPreview"
                      :on-exceed="exceedCount"
                      :limit="head.control.maxImage"
                      :on-remove="(file,fileList)=>handleRemove(control[headIdx],file,fileList)">
                      <i class="el-icon-plus" v-if="head.control.configurationForResponder.editable"></i>
                     
                    </el-upload>
                    </div>
                    
                    
                  </div>
                 
  
                </div>
          
            </el-form-item>
              
            </el-form>
            <!-- <div class="radio_content flex">
              <div class="radio_label" @click="radio_select=!radio_select">
                <img v-if="radio_select" src="https://global-cdn.bigplayers.com/m/img/yellow/select.png" alt="">
                <img v-else src="https://global-cdn.bigplayers.com/m/img/yellow/no-select.png" alt="">
               
              </div>
              <div class="radio_msg">Remember the information above</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
   
</template>

<script>

import cookies from '../utils/cookie'
import config_Api from '../../vue.config'
import { langMixins } from "../mixins/langMixins";

export default {
    mixins:[langMixins],
    props:{
        result:{}
    },
           /* 
              控件类型：文本-TextControl
              密码-PasswordControl
              数字-NumericControl
              单选-SingleSelectControl
              单选下拉-DropDownSingleSelectControl
              多选-MultiSelectControl
              多选下拉-DropDownMultiSelectControl
              图片-ImageControl
              */ 
    data(){
        return{
            payForm:{},
            rules:{
                region: [ { required: false, message: '请选择活动区域', trigger: 'blur' }],
                id: [  { required: true, message: 'Enter ID', trigger: 'blur' } ],
                email: [  { required: true, message: 'Please enter your e-mail', trigger: 'blur' } ],
                name: [  { required: true, message: 'Enter the full name.', trigger: 'blur' } ],
                cpf: [  { required: true, message: 'Please enter the CPF', trigger: 'blur' } ],
            },
            
            dialogVisible:false,
            baseUrl:'',
            upData:{},
            objList:[],
            showPic:true,
            defaultPic:[],
            fileList:[],
            fileList0:[],
            fileList1:[],
            fileList2:[],
            fileList3:[],
            fileList4:[],
            fileList5:[],
            
        }
    },
    created(){
        let tables = this.result[0].tables
        let that = this
        let defaultPic =[]
        this.upUrl = config_Api.devServer.proxy
       console.log("api==",config_Api.devServer.proxy)

        console.log('props....',this.result)

        this.result.map(r=>{
          r.tables.map((v,index)=>{
          v.headers.map((i,i_idx)=>{
            let defPicObj = []
              // console.log('iiiiiiiii',i)
              if(i.control.type == "ImageControl"){
                v.controls.map((c,c_idx)=>{
                //  let defaultFielList = []
                    c[i_idx].fileList = []
                    c[i_idx].values.map((t,t_idx)=>{
                      
                //  console.log(" eval('defaultPci' + t_idx)")
                    this.getPic(t,(data)=>{
                      // if(c[i_idx].configurationForResponder.editable){
                        let obj = {}
                        obj.url = data
                        console.log('configurationForResponder.editable',c[i_idx].configurationForResponder.editable)
                        defPicObj.push(obj)
                        this.fileList = defPicObj
                        c[i_idx].fileList.push(obj)
                      // }else{
                      //   defPicObj.push(data)
                      // }
                  
                      // defaultPic[i_idx] = defPicObj
                      // this.defaultPic[i_idx] = defaultPic[i_idx]
                      this.showPic =false
                     
                      this.$forceUpdate()
                      this.$nextTick(()=>{
                        that.showPic = true
                      })
                      console.log('defaultPic,defaultPic', c[i_idx].fileList,this.result)
                      return t
                    })
                    
                  })
                })
               
              } 
            
          })
          return v
        })
        })
        
        
    },
    methods:{
      getPic(pic,callback){
        this.$axiosApi.getPic({pic}).then(res=>{
        if(res.code == 200){
          console.log(res.data)
          callback(res.data)
         return res.data
        }
      })
      },
        handleInput(value,type){  
          let sId = document.getElementById(type)
          console.log('this.',type,sId)
          let arr =[]
          arr.push(sId)
          let _this = this
          this.$nextTick(() => {
            _this.execute(sId)
            _this.tranDocuments(arr)
            console.log('execute')
          })
            let result = this.result
            console.log('value',value)
            this.$emit('input', result)
        },
        handleAvatarSuccess(item,res,file,fileList) {
          console.log('上传成功',item,res,file,fileList)
          if(res.code == 200){
            this.$message.success('success')
            let values =item.values
            values.push(res.data.objectId)
            console.log('item',item)
            console.log('file',file)
            
            console.log('values',values)
            console.log('fileList',fileList)
            // item.values =values
          }else{
            this.$message.error('Upload failed')
            fileList.map((v,index)=>{
              if(v.uid == file.uid){
                fileList.splice(index,1)
              }
            })
            return
          }
       
        // this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(count,file,fileList) {
        // const isJPG = file.type === 'image/jpeg';
        // console.log(count,file,fileList)
        // const isLt2M = file.size / 1024 / 1024 < 2;

        // console.log('上传之前',file,fileList)
        // if (!isLt2M) {
        //   this.$message.error('The size of the uploaded image cannot exceed 2MB!');
        //   return false
        // }
       
        
      },
      handleRemove(item,file, fileList) {
        console.log(item,file, fileList);
     
        item.values.map((v,index)=>{
          if(file.response.data.objectId == v){
            item.values.splice(index,1)
          }
        })
       console.log('item',item)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      exceedCount(){
        this.$message.warning('Exceeding the limit quantity')
        return
      }
    },
    computed:{
      config(){return {'authorization':cookies.getToken()}}
    }
}
</script>

<style lang="scss" scoped>
.pay_list{
    margin-top:16px;
    // margin-bottom:64px;
    .pay_list_title{
      font-size: 14px;
      color:#161619;
      font-weight: bold;
      margin-bottom:12px;
      text-align: left;
    }
    .pay_list_mod{
      border-radius: 6px;
      background:#fff;
    //   border:1px solid #f77e1a;
      .pay_mod_top{
        padding:10px;
        .mod_agree{
          width:20px;
          height: 20px;
          margin-right:14px;
          background:url(https://global-cdn.bigplayers.com/m/img/yellow/select.png);
          background-size: 100% 100%;
        }
        .mod_img{
          height: 32px;
          >img{
            height: 100%;
            width: auto;
          }
        }
      }
      .form_content{
        padding:0 12px 15px;
        .radio_content{
          color:#222425;
          .radio_label{
            width:20px;
            height: 20px;
            margin-right:14px;
          }
        }
        /deep/.el-form-item {
      margin-bottom:10px;
    }
    /deep/.el-form-item__label{
    //   font-size: 13px;
    //   color:#161619;
    //   height: 34px;
    //   padding:0;
        width:100px;
    }
    .suffix_icon{
      height: 100%;
      // transform: rotate(-90deg);
      translate: transform 0.35s ease-in-out;
    }
    .suffix_icon_rotate{
      transform: rotate(180deg)
    }
      }
    }
    
  }
  .show_pic{
  width: 100%;
    >img{
      width:80px;
      height: 80px;
      padding:0 8px 8px 0;
    
    }
  }
  .up_content{
    padding:8px 0;
    div{
      display: flex;
      flex-wrap: wrap;
    }
  }
  /deep/.el-select{
    width: 100%;
  }
  /deep/.el-upload-list {
    display: flex;
    flex-wrap: wrap;
  }
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  /deep/.el-upload--picture-card {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item{
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
 /deep/ .el-upload-list--picture-card .el-upload-list__item-status-label i {
  
    position: absolute;
    width: 12px;
    height: 12px;
    right: 14px;
    // top: 10px;
}
.el-checkbox-group {
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
}
/deep/.el-form-item__content {
   
    text-align: left;
}
.more_col{
  >div{
    margin:2px 0;
  }
}

</style>